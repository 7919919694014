export const orderStatuses = {
    WAITING_FOR_IMPRESSION: 'Waiting For Impression',
    CASE_SUBMITTED: 'Case Submitted',
    CASE_SENT_FOR_DESIGN: 'Case Sent For Design',
    CASE_SENT_FOR_RUSH_DESIGN: 'Case Sent For Rush Design',
    CASE_REDESIGN: 'Case Redesign',
    CASE_ON_HOLD: 'Case On Hold',
    CASE_NEEDS_APPROVAL: 'Case Needs Approval',
    CASE_IN_PRODUCTION: 'Case In Production',
    CASE_CANCELLED: 'Case Cancelled',
    CASE_COMPLETE: 'Case Complete',
    UNKNOWN_STATUS: '',
}

export const colorForStatus = status => {
    switch (status) {
        case orderStatuses.WAITING_FOR_IMPRESSION:
            return '#d7aae3'
        case orderStatuses.CASE_SUBMITTED:
            return '#7f1399'
        case orderStatuses.CASE_SENT_FOR_DESIGN:
        case orderStatuses.CASE_SENT_FOR_RUSH_DESIGN:
            return '#92bd04'
        case orderStatuses.CASE_NEEDS_APPROVAL:
            return '#ff7900'
        case orderStatuses.CASE_REDESIGN:
            return '#fe94ab'
        case orderStatuses.CASE_CANCELLED:
            return '#787779'
        case orderStatuses.CASE_IN_PRODUCTION:
            return '#00c58e'
        case orderStatuses.CASE_COMPLETE:
            return '#0072cf'
        case orderStatuses.UNKNOWN_STATUS:
            return 'error'
        default:
            return 'info'
    }
}
