import React from 'react'
import { toast } from 'react-toastify'
import { API } from 'aws-amplify'
import config from '../config'
import { mockPracticesList, mockPractice } from './mock'

const PracticeStateContext = React.createContext()
const PracticeDispatchContext = React.createContext()

const apiName = 'admin-portal-api'

const initialData = {
    findLoading: false,
    findError: false,
    fetchLoading: false,
    fetchError: false,
    saveLoading: false,
    saveError: false,
    currentPractice: null,
    practices: null,
    searchInput: null,
    listPreferences: {
        order: 'desc',
        orderBy: 'createdAt',
        rowsPerPage: 50,
        page: 0,
    },
}

function practiceReducer(state = initialData, { type, payload }) {
    switch (type) {
        case 'PRACTICE_RESET_STATE':
            return { ...initialData }
        case 'PRACTICE_FORM_RESET':
            return { ...state, saveLoading: false, saveError: false, currentPractice: null }
        case 'PRACTICE_FORM_FIND_STARTED':
            return { ...state, currentPractice: null, findLoading: true, findError: false }
        case 'PRACTICE_FORM_FIND_SUCCESS':
            return { ...state, currentPractice: payload, findLoading: false }
        case 'PRACTICE_FORM_FIND_ERROR':
            return { ...state, currentPractice: null, findLoading: false, findError: true }
        case 'PRACTICE_FORM_SAVE_STARTED':
            return { ...state, saveLoading: true, saveError: false }
        case 'PRACTICE_FORM_SAVE_SUCCESS':
            return { ...state, saveLoading: false, currentPractice: null, practices: null }
        case 'PRACTICE_FORM_SAVE_ERROR':
            return { ...state, saveLoading: false, saveError: true }
        case 'PRACTICE_LIST_FETCH_STARTED':
            return { ...state, fetchLoading: true, fetchError: false }
        case 'PRACTICE_LIST_FETCH_SUCCESS':
            return { ...state, practices: payload, fetchLoading: false }
        case 'PRACTICE_LIST_FETCH_ERROR':
            return { ...state, practices: null, fetchLoading: false, fetchError: true }
        case 'PRACTICE_LIST_CHANGE_PREFERENCES':
            return { ...state, listPreferences: payload }
        case 'PRACTICE_LIST_SEARCH':
            return { ...state, searchInput: payload }
        default:
            return state
    }
}

function PracticeProvider({ children }) {
    var [state, dispatch] = React.useReducer(practiceReducer, initialData)

    return (
        <PracticeStateContext.Provider value={state}>
            <PracticeDispatchContext.Provider value={dispatch}>{children}</PracticeDispatchContext.Provider>
        </PracticeStateContext.Provider>
    )
}

function usePracticeState() {
    var context = React.useContext(PracticeStateContext)
    if (context === undefined) {
        throw new Error('usePracticeState must be used within a PracticeProvider')
    }
    return context
}

function usePracticeDispatch() {
    var context = React.useContext(PracticeDispatchContext)
    if (context === undefined) {
        throw new Error('usePracticeDispatch must be used within a PracticeProvider')
    }
    return context
}

function displayError(message, error) {
    console.log(error)
    const errorMsg = error.response?.data?.error || error.message
    const finalMessage = errorMsg ? `${message}: ${errorMsg}` : message
    toast.error(finalMessage)
}

// ###########################################################

const actions = {
    doNewForm: () => async dispatch => {
        dispatch({ type: 'PRACTICE_FORM_RESET' })
    },

    doFind: practiceId => async dispatch => {
        if (!practiceId) {
            console.error('Unable to find undefined practice')
            return
        }
        if (!config.isBackend) {
            dispatch({ type: 'PRACTICE_FORM_FIND_STARTED' })
            setTimeout(() => {
                dispatch({ type: 'PRACTICE_FORM_FIND_SUCCESS', payload: mockPractice })
            }, 1000)
        } else {
            try {
                dispatch({ type: 'PRACTICE_FORM_FIND_STARTED' })
                const practice = await API.get(apiName, `/practices/${practiceId}`)
                dispatch({ type: 'PRACTICE_FORM_FIND_SUCCESS', payload: practice })
            } catch (error) {
                displayError('Unable to find the specified practice', error)
                dispatch({ type: 'PRACTICE_FORM_FIND_ERROR' })
            }
        }
    },

    doCreate: values => async (dispatch, history) => {
        if (!config.isBackend) {
            dispatch({ type: 'PRACTICE_FORM_SAVE_SUCCESS' })
            history.push('/app/practice/list')
            setTimeout(() => toast.success('Practice created sucessfully'), 1000)
        } else {
            try {
                dispatch({ type: 'PRACTICE_FORM_SAVE_STARTED' })
                await API.post(apiName, '/practices', { body: values })
                dispatch({ type: 'PRACTICE_FORM_SAVE_SUCCESS' })
                history.push('/app/practice/list')
                setTimeout(() => toast.success('Practice created sucessfully'), 1000)
            } catch (error) {
                displayError('Unable to create practice', error)
                dispatch({ type: 'PRACTICE_FORM_SAVE_ERROR' })
            }
        }
    },

    doUpdate: (practiceId, values) => async (dispatch, history) => {
        if (!config.isBackend) {
            dispatch({ type: 'PRACTICE_FORM_SAVE_SUCCESS' })
            toast.success('Practice updated sucessfully')
            setTimeout(() => history.push('/app/practice/list'), 2000)
        } else {
            try {
                dispatch({ type: 'PRACTICE_FORM_SAVE_STARTED' })
                await API.patch(apiName, `/practices/${practiceId}`, { body: values })
                dispatch({ type: 'PRACTICE_FORM_SAVE_SUCCESS' })
                toast.success('Practice updated sucessfully')
                history.push('/app/practice/list')
            } catch (error) {
                displayError('Unable to update practice', error)
                dispatch({ type: 'PRACTICE_FORM_SAVE_ERROR' })
            }
        }
    },

    doFetch: (filter = null) => async dispatch => {
        if (!config.isBackend) {
            dispatch({ type: 'PRACTICE_LIST_FETCH_STARTED' })
            setTimeout(() => {
                dispatch({ type: 'PRACTICE_LIST_FETCH_SUCCESS', payload: mockPracticesList })
            }, 1000)
        } else {
            try {
                dispatch({ type: 'PRACTICE_LIST_FETCH_STARTED' })
                const data = await API.get(apiName, '/practices', { queryStringParameters: filter })
                dispatch({ type: 'PRACTICE_LIST_FETCH_SUCCESS', payload: data })
            } catch (error) {
                displayError('Unable to find practices', error)
                dispatch({ type: 'PRACTICE_LIST_FETCH_ERROR' })
            }
        }
    },

    doResetPassword: practiceEmail => async dispatch => {
        if (!practiceEmail) {
            toast.error('Incorrect email address to reset')
        } else if (!config.isBackend) {
            setTimeout(() => {
                toast.success('Password has been reset')
            }, 1000)
        } else {
            try {
                await API.post(apiName, '/practices/reset-password', { body: { email: practiceEmail } })
                toast.success('Password has been reset')
            } catch (error) {
                displayError('Unable to reset password for this practice', error)
            }
        }
    },

    doChangeListPreferences: preferences => dispatch => {
        dispatch({ type: 'PRACTICE_LIST_CHANGE_PREFERENCES', payload: preferences })
    },

    doSearchPractices: searchInput => dispatch => {
        dispatch({ type: 'PRACTICE_LIST_SEARCH', payload: searchInput })
    },
}

export { PracticeProvider, usePracticeState, usePracticeDispatch, actions }
