import React from 'react';
import Widget from '../Widget';
import { Link } from 'react-router-dom';
import { Box, Grid, Breadcrumbs } from '@material-ui/core';
import { Typography } from '../Wrappers';
import { NavigateNext as NavigateNextIcon, CalendarToday as CalendarIcon } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

// styles
import useStyles from '../Layout/styles';

// components
import structure from '../Sidebar/SidebarStructure';

const todaysDate = () => {
    var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    return new Date().toLocaleDateString('en-AU', options);
};

const BreadCrumbs = () => {
    const location = useLocation();
    const classes = useStyles();

    const renderBreadCrumbs = () => {
        let url = location.pathname;
        let route = url
            .split('/')
            .slice(1)
            .map((route) =>
                route
                    .split('-')
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(' ')
            );
        const length = route.length;
        return route.map((item, index) => {
            let middlewareUrl =
                '/' +
                url
                    .split('/')
                    .slice(1, index + 2)
                    .join('/');

            return (
                <Breadcrumbs
                    key={index + '_b'}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Typography variant="h6" color={length === index + 1 ? 'primary' : ''}>
                        {length === index + 1 ? (
                            item
                        ) : (
                            <Link to={middlewareUrl} style={{ color: 'unset', textDecoration: 'none' }}>
                                {item}
                            </Link>
                        )}
                    </Typography>
                </Breadcrumbs>
            );
        });
    };

    const renderStructureElt = (elt) => {
        if (!elt.children && window.location.hash.includes(elt.link) && elt.link) {
            return (
                <Box display="flex" alignItems="center" key={elt.id}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h4">{elt.label}</Typography>
                    </Breadcrumbs>
                </Box>
            );
        } else {
            return null;
        }
    };

    return (
        <Widget disableWidgetMenu inheritHeight className={classes.margin} bodyClass={classes.navPadding}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap={'nowrap'}
                style={{ overflowX: 'auto', padding: '5px' }}
            >
                {structure.map(renderStructureElt)}
                {window.location.hash.includes('/app/dashboard') ? (
                    <Box display="flex" alignItems="center">
                        <CalendarIcon className={classes.calendarIcon} />
                        <Typography className={classes.date}>{todaysDate()}</Typography>
                    </Box>
                ) : (
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        {renderBreadCrumbs()}
                    </Breadcrumbs>
                )}
            </Grid>
        </Widget>
    );
};
export default BreadCrumbs;
