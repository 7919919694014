import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// context
import { usePracticeState } from '../../../context/PracticeContext';

const CaseAttributes = ({ newOrder, formErrors, onTextFieldChange }) => {
    const practiceState = usePracticeState();

    return (
        <>
            <FormControl key="practice" variant="outlined" onChange={onTextFieldChange} style={{ marginBottom: 35 }}>
                <InputLabel id="practice-label" required>
                    Select a practice
                </InputLabel>
                <Select
                    labelId="practice-label"
                    id="practice"
                    value={newOrder.practiceId}
                    name="practiceId"
                    onChange={onTextFieldChange}
                    label="Practice"
                    error={formErrors.practiceId}
                >
                    {(practiceState.practices?.items || []).map((item) => (
                        <MenuItem key={item.practiceId} value={item.practiceId}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                id="prescriberName"
                label="Prescriber Name"
                onChange={onTextFieldChange}
                name="prescriberName"
                value={newOrder.prescriberName || ''}
                variant="outlined"
                style={{ marginBottom: 35 }}
                helperText="Please enter the prescriber first name and last name"
                required
                error={formErrors.prescriberName}
            />

            <TextField
                id="patientName"
                label="Patient Name"
                onChange={onTextFieldChange}
                value={newOrder.patientName || ''}
                name="patientName"
                variant="outlined"
                style={{ marginBottom: 35 }}
                helperText="Please enter the patient first name and last name"
                required
                error={formErrors.patientName}
            />
        </>
    );
};

export default CaseAttributes;
