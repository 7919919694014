import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

// components
import { Typography } from '../../../components/Wrappers/Wrappers'
import Notification from '../../../components/Notification/Notification'

// styles
import useStyles from '../styles'

// form attributes
import { impressionMethod } from '../helpers/form'

// context
import { useOrderState } from '../../../context/OrderContext'

const orderCreationInProgress = classes => (
    <>
        <CircularProgress color="secondary" className={classes.progressCentered} />
        <Typography variant="h5" color="primary" className={classes.orderSubmitting}>
            Your order is being created
        </Typography>
    </>
)

const orderCreationFailure = classes => (
    <>
        <Typography variant="h3" color="primary" className={classes.orderSubmitted}>
            An error occurred trying to create your order
        </Typography>
        <Typography variant="h5" color="primary" className={classes.orderSubmitted}>
            Please, try again later or contact the support
        </Typography>
        <Notification className={classes.notificationItemIcon} type="report" variant="rounded" color="warning" />
    </>
)

const orderCreationSuccess = (classes, newOrder) => {
    const provideTreatmentPlan = newOrder.provideTreatmentPlan === 'Yes'
    const isImpressionMethod = newOrder.method === impressionMethod

    const instructions = () => (
        <>
            {isImpressionMethod ? (
                <>
                    <Typography color="primary" className={classes.orderSubmitted}>
                        Please send your impression moulds to:
                    </Typography>
                    <Typography weight="bold" color="primary" className={classes.orderSubmitted}>
                        Smile Academy - PO Box 355, Lutwyche QLD 4030
                    </Typography>
                    <Typography color="primary" className={classes.orderSubmitted}>
                        Once Smile Academy has scanned and uploaded your impression, a confirmation email will be sent
                        to you.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography color="primary" className={classes.orderSubmitted}>
                        Your patient’s treatment plan will be ready in our portal within 3 working days.
                    </Typography>
                    <Typography color="primary" className={classes.orderSubmitted}>
                        You will receive an email to let you know when it is ready for your approval or modification.
                    </Typography>
                </>
            )}
        </>
    )

    return (
        <>
            <Typography variant="h3" color="primary" className={classes.orderSubmitted}>
                {provideTreatmentPlan
                    ? 'Congratulations, your order has been placed and sent to production!'
                    : 'Congratulations, your order has been placed!'}
            </Typography>

            {!provideTreatmentPlan && instructions()}

            <Notification className={classes.notificationItemIcon} type="shipped" variant="rounded" color="success" />
        </>
    )
}

const OrderConfirmation = ({ newOrder }) => {
    const classes = useStyles()
    const orderState = useOrderState()

    const isSaving = orderState.saveLoading
    const hasFailed = orderState.saveError

    return (
        <>
            {isSaving
                ? orderCreationInProgress(classes)
                : hasFailed
                ? orderCreationFailure(classes)
                : orderCreationSuccess(classes, newOrder)}
        </>
    )
}

export default OrderConfirmation
