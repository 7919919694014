import { Auth } from 'aws-amplify';

const configVariables = {
    // if isBackend is false, data will be mocked on the frontend. If true, if will use this config file to fetch data
    isBackend: process.env.REACT_APP_BACKEND === 'true',
    Auth: {
        email: 'admin@smile-academy.com.au', // used for local sign in only
        password: 'password',
        mandatorySignIn: true,
        region: 'ap-southeast-2',
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: 'admin-portal-api',
                endpoint: process.env.REACT_APP_API_GATEWAY_URL,
                region: 'ap-southeast-2',
                custom_header: async () => {
                    try {
                        const session = await Auth.currentSession();
                        return { Authorization: session.idToken.jwtToken };
                    } catch (error) {
                        return;
                    }
                },
            },
        ],
    },
};

export default configVariables;
