import React, { useState } from 'react';
import { IconButton, MenuItem, Select } from '@material-ui/core';
import { ArrowUpwardRounded, ArrowDownwardRounded } from '@material-ui/icons';

import PropTypes from 'prop-types';

const SortTableSelect = ({ headers, order, orderBy, onRequestSort }) => {
    const [sortingProperty, setSortingProperty] = useState(orderBy);
    const createSortHandler = (event, value) => onRequestSort(event, value);

    // =================== Handlers ======================

    const handleSelectionChange = (event) => {
        setSortingProperty(event.target.value);
        createSortHandler(event, event.target.value);
    };

    const handleSortingDirectionChange = (event) => {
        createSortHandler(event, sortingProperty);
    };

    const handleValueRender = (selectedOption) => {
        if (!selectedOption) {
            return <span>Sort by</span>;
        }

        return headers.find((header) => header.id === selectedOption).label;
    };

    return (
        <>
            <IconButton onClick={handleSortingDirectionChange}>
                {order === 'asc' ? (
                    <ArrowUpwardRounded label="Ascending sorting direction" />
                ) : (
                    <ArrowDownwardRounded label="Descending sorting direction" />
                )}
            </IconButton>

            <Select
                displayEmpty
                color="secondary"
                value={sortingProperty}
                onChange={handleSelectionChange}
                style={{ width: '100%' }}
                renderValue={handleValueRender}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {headers
                    .filter((header) => header.isSortable)
                    .map((header) => (
                        <MenuItem key={header.id} value={header.id}>
                            {header.label}
                        </MenuItem>
                    ))}
            </Select>
        </>
    );
};

SortTableSelect.propTypes = {
    headers: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default SortTableSelect;
