import React, { useEffect } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'

// pages
import Dashboard from '../../pages/dashboard'
import OrderAdd from '../../pages/order/AddOrder'
import OrderView from '../../pages/order/ViewOrder'
import OrderEdit from '../../pages/order/EditOrder'
import OrderRefine from '../../pages/order/RefineOrder'
import OrderList from '../../pages/order/OrderList'
import PracticeAdd from '../../pages/practice/AddPractice'
import PracticeList from '../../pages/practice/PracticeList'
import PracticeView from '../../pages/practice/ViewPractice'
import BreadCrumbs from '../../components/BreadCrumbs'

// context
import { useLayoutState } from '../../context/LayoutContext'
import { useUserState, useUserDispatch, actions } from '../../context/UserContext'

//Sidebar structure
import structure from '../Sidebar/SidebarStructure'

function Layout(props) {
    const classes = useStyles()
    const layoutState = useLayoutState()
    const userState = useUserState()
    const userDisptach = useUserDispatch()

    useEffect(() => {
        if (!userState.currentUser) {
            actions.getCurrentUser(props.history)(userDisptach)
        }
    }, []) // eslint-disable-line

    return (
        <div className={classes.root}>
            <Header history={props.history} />
            <Sidebar structure={structure} />
            <div
                className={classnames(classes.content, {
                    [classes.contentShift]: layoutState.isSidebarOpened,
                })}
            >
                <div className={classes.fakeToolbar} />
                <BreadCrumbs />
                <Switch>
                    <Route path="/app/dashboard" component={Dashboard} />
                    <Route
                        exact
                        path="/app/order"
                        render={() => <Redirect to="/app/order/list?orderStatus=in-progress" />}
                    />
                    <Route path="/app/order/add" component={OrderAdd} />
                    <Route path="/app/order/view" component={OrderView} />
                    <Route path="/app/order/edit" component={OrderEdit} />
                    <Route path="/app/order/refine" component={OrderRefine} />
                    <Route path="/app/order/list" component={OrderList} />
                    <Route exact path="/app/practice" render={() => <Redirect to="/app/practice/list" />} />
                    <Route path="/app/practice/add" component={PracticeAdd} />
                    <Route path="/app/practice/view" component={PracticeView} />
                    <Route path="/app/practice/list" component={PracticeList} />
                </Switch>
            </div>
        </div>
    )
}

export default withRouter(Layout)
