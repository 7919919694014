import React from 'react'
import {
    Home as HomeIcon,
    Description as DescriptionIcon,
    PersonPin as PersonPinIcon,
    People as PeopleIcon,
    FormatListBulleted as FormatListBulletedIcon,
} from '@material-ui/icons'

const structure = [
    { id: 0, label: 'Dashboard', link: '/app/dashboard', icon: <HomeIcon /> },
    { id: 1, type: 'divider' },
    { id: 2, type: 'title', label: 'ORDERS' },
    {
        id: 3,
        label: 'Create Order',
        link: '/app/order/add',
        icon: <DescriptionIcon />,
    },
    {
        id: 4,
        label: 'View Orders',
        link: '/app/order/list?orderStatus=in-progress',
        icon: <FormatListBulletedIcon />,
    },
    { id: 5, type: 'divider' },
    { id: 6, type: 'title', label: 'PRACTICES' },
    {
        id: 7,
        label: 'Create Practice',
        link: '/app/practice/add',
        icon: <PersonPinIcon />,
    },
    {
        id: 8,
        label: 'View Practices',
        link: '/app/practice/list',
        icon: <PeopleIcon />,
    },
]

export default structure
