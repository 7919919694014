import React, { useState } from 'react'
import { Grid, CircularProgress, Fade, TextField as Input } from '@material-ui/core'
import { withRouter, useHistory } from 'react-router-dom'

// styles
import useStyles from './styles'

// logo
import logo from '../../images/logo-large-dark.svg'

// context
import { useUserDispatch, useUserState, actions } from '../../context/UserContext'

//components
import { Button, Typography } from '../../components/Wrappers'

function Reset() {
    const classes = useStyles()
    const history = useHistory()
    const userDispatch = useUserDispatch()
    const userState = useUserState()

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const doCompletePassword = () => {
        actions.completeNewPassword(userState.currentUser, password, history)(userDispatch)
    }

    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage} />
                <Typography className={classes.logotypeText}>Admin | Smile Academy</Typography>
            </div>
            <div className={classes.customFormContainer}>
                <div className={classes.form}>
                    <Typography variant="h4" className={classes.formTitle}>
                        CHOOSE PASSWORD
                    </Typography>
                    <div className={classes.formDividerContainer}>
                        <div className={classes.formDivider} />
                    </div>
                    <Fade
                        in={userState.errorMessage.length > 0}
                        style={!userState.errorMessage.length === 0 ? { display: 'none' } : { display: 'inline-block' }}
                    >
                        <Typography color="secondary" className={classes.errorMessage}>
                            {userState.errorMessage}
                        </Typography>
                    </Fade>
                    <Input
                        id="password"
                        InputProps={{
                            classes: {
                                underline: classes.InputUnderline,
                                input: classes.Input,
                            },
                        }}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        margin="normal"
                        placeholder="Password"
                        type="password"
                        fullWidth
                    />
                    <Input
                        id="confirmPassword"
                        InputProps={{
                            classes: {
                                underline: classes.InputUnderline,
                                input: classes.Input,
                            },
                        }}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        margin="normal"
                        placeholder="Confirm Password"
                        type="password"
                        fullWidth
                    />
                    <Typography className={classes.passwordRules}>
                        Password must be at least 6 characters long, requires 1 number, 1 uppercase and 1 lowercase
                        letter
                    </Typography>
                    <div className={classes.formButtons}>
                        {userState.isLoading ? (
                            <CircularProgress size={26} className={classes.loginLoader} />
                        ) : (
                            <Button
                                className={classes.button}
                                disabled={
                                    password.length === 0 ||
                                    confirmPassword.length === 0 ||
                                    password !== confirmPassword
                                }
                                onClick={() => doCompletePassword()}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                change password
                            </Button>
                        )}
                    </div>
                </div>
                <Typography color="primary" className={classes.copyright}>
                    © 2020 Smile Academy Pty Ltd. All rights reserved.
                </Typography>
            </div>
        </Grid>
    )
}

export default withRouter(Reset)
