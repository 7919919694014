import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'

// styles
import useStyles from '../styles'

// components
import Widget from '../../../components/Widget/Widget'

const Breadcrumb = ({ activeStep, stepsTitle }) => {
    const classes = useStyles()
    return (
        <Grid item xs={12}>
            <Widget>
                <Stepper activeStep={activeStep}>
                    {stepsTitle.map(label => (
                        <Step key={label}>
                            <StepLabel classes={{ completed: classes.stepCompleted }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Widget>
        </Grid>
    )
}

export default Breadcrumb
