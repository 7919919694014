import React, { useState, useEffect } from 'react'
import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Menu as MenuIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import classNames from 'classnames'

// styles
import useStyles from './styles'

// components
import { Typography } from '../Wrappers/Wrappers'

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext'
import { useUserState, useUserDispatch, actions as userActions } from '../../context/UserContext'
import { useOrderDispatch, actions as orderActions } from '../../context/OrderContext'

export default function Header(props) {
    const classes = useStyles()
    const theme = useTheme()

    // global
    const layoutState = useLayoutState()
    const layoutDispatch = useLayoutDispatch()
    const userState = useUserState()
    const userDispatch = useUserDispatch()
    const orderDispatch = useOrderDispatch()

    // local
    const [isSmall, setSmall] = useState(false)

    const practiceName = userState.currentUser?.attributes?.name || ''

    useEffect(function() {
        window.addEventListener('resize', handleWindowWidthChange)
        handleWindowWidthChange()
        return function cleanup() {
            window.removeEventListener('resize', handleWindowWidthChange)
        }
    })

    const handleWindowWidthChange = () => {
        var windowWidth = window.innerWidth
        var breakpointWidth = theme.breakpoints.values.md
        var isSmallScreen = windowWidth < breakpointWidth
        setSmall(isSmallScreen)
    }

    const handleSignOut = () => {
        userActions.signOut(props.history)(userDispatch)
        orderActions.doResetState()(orderDispatch)
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(classes.headerMenuButton, classes.headerMenuButtonCollapse)}
                >
                    {(!layoutState.isSidebarOpened && isSmall) || (layoutState.isSidebarOpened && !isSmall) ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(classes.headerIcon, classes.headerIconCollapse),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(classes.headerIcon, classes.headerIconCollapse),
                            }}
                        />
                    )}
                </IconButton>
                <Typography variant="h3">Admin Portal</Typography>
                <div className={classes.grow} />
                <Typography block variant="body2" className={classes.headerRightLabel}>
                    <Typography variant="body2" weight={'bold'}>
                        {practiceName}
                    </Typography>
                </Typography>
                <Button variant="contained" className={classes.headerButton} onClick={handleSignOut}>
                    Sign Out
                </Button>
            </Toolbar>
        </AppBar>
    )
}
