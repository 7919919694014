import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Grid, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { sentenceCase } from 'sentence-case';
import moment from 'moment';

// styles
import useStyles from './styles';

// components
import { Typography } from '../../components/Wrappers/Wrappers';
import Widget from '../../components/Widget/Widget';

// context
import { usePracticeDispatch, usePracticeState, actions } from '../../context/PracticeContext';

const ViewPractice = () => {
    const classes = useStyles();
    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();
    const history = useHistory();
    const location = useLocation();

    const entries = Object.entries(practiceState?.currentPractice || {});

    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const practiceId = queryString.get('practiceId');
        if (!practiceId) {
            toast.error('Unable to find practice id');
            setTimeout(() => backToList(), 2000);
        } else if (!practiceState.currentPractice || practiceState.currentPractice.practiceId !== practiceId) {
            actions.doFind(practiceId)(practiceDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        if (!practiceState.findError) return;
        setTimeout(() => backToList(), 2000);
    }, [practiceState?.findError]); //eslint-disable-line

    const backToList = () => history.goBack();

    const handleResetPassword = () => {
        actions.doResetPassword(practiceState?.currentPractice?.email)(practiceDispatch);
    };

    const renderEntry = ([key, value]) => {
        if (!value || value === '' || key === 'practiceId' || key === 'fullAddress') return null;
        if (key === 'userStatus') return renderPracticeStatus(key, value);
        return (
            <Grid item container key={key}>
                <Grid item xs={6}>
                    <Typography variant="body1" weight="bold">
                        {key === 'abn' ? key.toUpperCase() : sentenceCase(key)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {key === 'createdAt' ? (
                        <Typography className={classes.text}>{moment(value).format('DD/MM/YY hh:mm a')}</Typography>
                    ) : key === 'pricingNotes' ? (
                        value.split('\n').map((item, idx) => (
                            <span key={idx}>
                                <Typography className={classes.text}>{item}</Typography>
                            </span>
                        ))
                    ) : (
                        <Typography className={classes.text}>{value}</Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderPracticeStatus = (key, value) => (
        <Grid item container alignItems="center" key={key}>
            <Grid item xs={6}>
                <Typography variant="body1" weight="bold">
                    {sentenceCase(key)}
                </Typography>
            </Grid>
            <Grid
                item
                container
                xs={6}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '12px',
                }}
            >
                <Typography className={classes.text}>{sentenceCase(value)}</Typography>
                <Button color="primary" variant="contained" onClick={handleResetPassword}>
                    Reset
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={600}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 30 }}>
                                Practice details
                            </Typography>
                            <Grid container direction="column" spacing={3}>
                                {practiceState?.findLoading ? (
                                    <CircularProgress color="secondary" className={classes.progressCentered} />
                                ) : (
                                    entries.map(renderEntry)
                                )}
                            </Grid>
                            <Box display="flex" justifyContent="center" style={{ marginTop: 50 }}>
                                <Button onClick={backToList} variant="outlined" color="secondary">
                                    Back
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default ViewPractice;
