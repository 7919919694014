import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { Typography } from '../../components/Wrappers';
import PropTypes from 'prop-types';

const EnhancedTableHead = ({ classes, headers, order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => onRequestSort(event, property);
    return (
        <TableHead>
            <TableRow>
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignLeft ? 'left' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.isSortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={order}
                                onClick={createSortHandler(headCell.id)}
                                style={{
                                    whiteSpace: 'nowrap',
                                    textTransform: 'uppercase',
                                    fontSize: '0.85rem',
                                }}
                            >
                                <Typography uppercase color="text" variant={'body2'} colorBrightness="hint">
                                    {headCell.label}
                                </Typography>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <Typography uppercase color="text" variant={'body2'} colorBrightness="hint">
                                {headCell.label}
                            </Typography>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
