import React, { useEffect, useState } from 'react';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TextField,
    Toolbar,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Search as SearchIcon } from '@material-ui/icons';

import moment from 'moment';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from './styles';

// components
import { RemoveRedEye as ViewIcon, Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Widget from '../../components/Widget';
import EnhancedTableHead from '../../components/Table/EnhancedTableHead';
import EmptyTableRow from '../../components/Table/EmptyTableRow';

// context
import { usePracticeDispatch, usePracticeState, actions } from '../../context/PracticeContext';

// helpers
import { stableSort, getSorting } from '../../common/helpers';
import SortTableSelect from '../../components/Table/SortTableSelect';

const headCells = [
    { id: 'tradingName', alignLeft: true, disablePadding: false, label: 'Trading Name', isSortable: true },
    { id: 'territory', alignLeft: true, disablePadding: false, label: 'Territory', isSortable: true },
    { id: 'prescriber1', alignLeft: true, disablePadding: false, label: 'Principal Prescriber', isSortable: true },
    { id: 'email', alignLeft: true, disablePadding: false, label: 'Email', isSortable: true },
    { id: 'createdAt', alignLeft: true, disablePadding: false, label: 'Date of creation', isSortable: true },
    { id: 'action', alignLeft: true, disablePadding: false, label: 'Action', isSortable: false },
];

/**
 * Returns practices matching the search input
 */
const filterItems = (practices, input) => {
    var regexp = new RegExp(input, 'gi');
    return practices.filter(
        (p) =>
            p.tradingName?.match(regexp) ||
            p.email?.match(regexp) ||
            p.territory?.match(regexp) ||
            p.prescriber1?.match(regexp) ||
            p.abn?.match(regexp)
    );
};

const PracticeTableRow = ({ classes, headers, row, onViewPractice, onEditPractice }) => (
    <TableRow hover tabIndex={-1}>
        <TableCell label={headers[0].label}>{row.tradingName}</TableCell>
        <TableCell label={headers[1].label}>{row.territory}</TableCell>
        <TableCell label={headers[2].label}>{row.prescriber1}</TableCell>
        <TableCell label={headers[3].label}>{row.email}</TableCell>
        <TableCell label={headers[4].label}>{row.createdAt ? moment(row.createdAt).format('DD/MM/YY') : ''}</TableCell>
        <TableCell label={headers[5].label} align="left" style={{ whiteSpace: 'nowrap', display: 'flex', gap: '16px' }}>
            <IconButton
                className={classes.actionsIcon}
                aria-owns="actions-menu"
                aria-haspopup="true"
                onClick={onViewPractice}
            >
                <ViewIcon color="primary" />
            </IconButton>
            <IconButton
                className={classes.actionsIcon}
                aria-owns="actions-menu"
                aria-haspopup="true"
                onClick={onEditPractice}
            >
                <EditIcon color="primary" />
            </IconButton>
        </TableCell>
    </TableRow>
);

PracticeTableRow.propTypes = {
    classes: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired,
    onViewPractice: PropTypes.func.isRequired,
    onEditPractice: PropTypes.func.isRequired,
};

const EnhancedTableToolbar = ({ onTextChange, value, onRequestSort, headers, order, orderBy }) => {
    const displaySortTableSelect = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        <Toolbar style={{ marginTop: 10 }}>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                    <TextField
                        color="secondary"
                        type="search"
                        placeholder="Search"
                        fullWidth
                        onChange={onTextChange}
                        value={value || ''}
                    />
                </Grid>
                {displaySortTableSelect && (
                    <Grid item xs={12} md={4} style={{ display: 'flex', marginBottom: 10 }}>
                        <SortTableSelect
                            headers={headers}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={onRequestSort}
                        ></SortTableSelect>
                    </Grid>
                )}
            </Grid>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    onTextChange: PropTypes.func.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    headers: PropTypes.array.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const PracticeList = () => {
    const classes = useStyles();
    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();
    const history = useHistory();

    // order and page preferences
    const { order, orderBy, rowsPerPage, page } = practiceState.listPreferences;

    // Search feature
    const [filteredItems, setFilteredItems] = useState([]);

    // =================== Hooks ======================

    useEffect(() => {
        if (!practiceState.practices) {
            actions.doFetch()(practiceDispatch);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        setFilteredItems(practiceState.practices?.items || []);
    }, [practiceState.practices]); // eslint-disable-line

    useEffect(() => {
        const input = practiceState.searchInput || '';
        const practices = practiceState.practices?.items || [];
        if (input.length === 0) {
            setFilteredItems(practices);
        } else if (input.length > 2) {
            setFilteredItems(filterItems(practices, input));
        }
    }, [practiceState.searchInput]); // eslint-disable-line

    // =================== Handlers ======================

    const handleRequestSort = (_, property) => {
        const isDesc = orderBy === property && order === 'desc';
        const preferences = { ...practiceState.listPreferences, order: isDesc ? 'asc' : 'desc', orderBy: property };
        actions.doChangeListPreferences(preferences)(practiceDispatch);
    };

    const handleChangePage = (_, newPage) => {
        const preferences = { ...practiceState.listPreferences, page: newPage };
        actions.doChangeListPreferences(preferences)(practiceDispatch);
    };

    const handleChangeRowsPerPage = (event) => {
        const preferences = {
            ...practiceState.listPreferences,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        };
        actions.doChangeListPreferences(preferences)(practiceDispatch);
    };

    const handleSearchInputChange = (e) => {
        actions.doSearchPractices(e.target.value)(practiceDispatch);
    };

    const handleViewPractice =
        ({ practiceId }) =>
        () =>
            history.push(`view?practiceId=${practiceId}`);

    const handleEditPractice =
        ({ practiceId }) =>
        () =>
            history.push(`add?practiceId=${practiceId}`);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget noBodyPadding bodyClass={classes.tableWidget}>
                    <EnhancedTableToolbar
                        value={practiceState.searchInput}
                        onTextChange={handleSearchInputChange}
                        onRequestSort={handleRequestSort}
                        headers={headCells}
                        order={order}
                        orderBy={orderBy}
                    />

                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="recent orders">
                            <EnhancedTableHead
                                classes={classes}
                                headers={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={filteredItems.length}
                            />

                            <TableBody>
                                {(practiceState.fetchLoading || filteredItems?.length === 0) && (
                                    <EmptyTableRow
                                        nbColumns={headCells.length}
                                        isLoading={practiceState.fetchLoading}
                                        label="No practice found"
                                    />
                                )}

                                {!practiceState.fetchLoading &&
                                    filteredItems?.length > 0 &&
                                    stableSort(filteredItems, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <PracticeTableRow
                                                key={row.practiceId}
                                                classes={classes}
                                                headers={headCells}
                                                row={row}
                                                onViewPractice={handleViewPractice(row)}
                                                onEditPractice={handleEditPractice(row)}
                                            />
                                        ))}
                            </TableBody>
                        </Table>
                    </div>

                    <TablePagination
                        rowsPerPageOptions={[50, 100]}
                        component="div"
                        count={filteredItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'previous page' }}
                        nextIconButtonProps={{ 'aria-label': 'next page' }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Widget>
            </Grid>
        </Grid>
    );
};

export default PracticeList;
