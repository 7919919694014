import React from 'react'
import Box from '@material-ui/core/Box'

// components
import { Button } from '../../../components/Wrappers/Wrappers'

// form helper
import { defaultFormStepsTitle, refinementFormStepsTitle } from '../helpers/form'

// context
import { useUserState } from '../../../context/UserContext'

const FormFooter = ({ activeStep, isRefinement, onClickNext, onClickBack }) => {
    const userState = useUserState()

    const formSteps = isRefinement ? refinementFormStepsTitle : defaultFormStepsTitle
    const isFirstStep = activeStep === 0 || (!userState.isOrtho && activeStep === 1)
    const isLastStep = activeStep === formSteps.length - 1
    const isSubmitStep = activeStep === formSteps.length - 2

    return (
        <div>
            <div>
                <Box display="flex" justifyContent={isFirstStep ? 'flex-end' : isLastStep ? 'center' : 'space-between'}>
                    {!isFirstStep && !isLastStep && (
                        <Button onClick={onClickBack} variant="outlined" color="secondary">
                            Back
                        </Button>
                    )}
                    <Button variant="contained" color="secondary" onClick={onClickNext}>
                        {isLastStep ? 'Return to Dashboard' : isSubmitStep ? 'Submit' : 'Next'}
                    </Button>
                </Box>
            </div>
        </div>
    )
}

export default FormFooter
