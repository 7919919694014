import { makeStyles } from '@material-ui/styles';
import tinycolor from 'tinycolor2';

export default makeStyles((theme) => ({
    // Create Order
    icon: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& .MuiSvgIcon-root': {
            marginRight: 5,
        },
    },
    stepCompleted: {
        root: {
            color: 'green',
        },
    },
    layoutContainer: {
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        border: '1px dashed',
        borderColor: theme.palette.primary.main,
        position: 'relative',
    },
    layoutText: {
        color: tinycolor(theme.palette.background.light).darken().toHexString(),
    },
    layoutButtonsRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    layoutButton: {
        backgroundColor: theme.palette.background.light,
        width: 125,
        height: 50,
        outline: 'none',
        border: 'none',
    },
    layoutButtonActive: {
        backgroundColor: tinycolor(theme.palette.background.light).darken().toHexString(),
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: theme.spacing(2),
    },
    notificationCallButton: {
        color: 'white',
        marginBottom: theme.spacing(1),
        textTransform: 'none',
    },
    codeContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
    },
    codeComponent: {
        flexGrow: 1,
    },
    notificationItem: {
        marginTop: theme.spacing(2),
        margin: '0 auto',
    },
    notificationItemIcon: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
        margin: '0 auto',
    },
    notificationCloseButton: {
        position: 'absolute',
        right: theme.spacing(2),
    },
    toastsContainer: {
        width: 'auto',
        marginTop: theme.spacing(6),
        right: 0,
    },
    progress: {
        visibility: 'hidden',
    },
    notification: {
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        boxShadow: 'none',
        overflow: 'visible',
    },
    searchIcon: {
        color: 'rgba(0, 0, 0, 0.23)',
    },
    imgWrap: {
        height: 100,
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: '0 0 10px 0px #ccc',
        marginRight: 20,
        marginBottom: 20,
        maxWidth: 150,
        position: 'relative',
    },
    photoReqWrap: {
        height: 350,
        marginRight: 75,
        marginBottom: 30,
        position: 'relative',
        textAlign: 'center',
    },
    galleryWrap: {
        display: 'flex',
        marginBottom: theme.spacing(3),
    },
    uploadLabel: {
        backgroundColor: tinycolor(theme.palette.secondary.main),
        color: '#f4f4f4',
        maxWidth: 220,
        display: 'inline-block',
        borderRadius: 8,
        textAlign: 'center',
        padding: '8px 12px',
        margin: '20px 0',
    },
    deleteImageX: {
        fontSize: 20,
        position: 'absolute',
        top: 5,
        left: 5,
        cursor: 'pointer',
        lineHeight: 0.5,
        color: 'white',
    },
    orderSubmitting: {
        textAlign: 'center',
        marginBottom: theme.spacing(5),
    },
    orderSubmitted: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    progressCentered: {
        marginBottom: theme.spacing(2),
        margin: '0 auto',
    },

    // OrderList
    card: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    progressSection: {
        marginBottom: theme.spacing(1),
    },
    progressTitle: {
        marginBottom: theme.spacing(2),
    },
    pieChartLegendWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginRight: theme.spacing(1),
    },
    legendItemContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        width: '100%',
    },
    fullHeightBody: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    alignStandaloneElement: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    tableWidget: {
        overflowX: 'auto',
    },
    progressBar: {
        backgroundColor: theme.palette.warning.main,
    },
    performanceLegendWrapper: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    legendElement: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
    legendElementText: {
        marginLeft: theme.spacing(1),
    },
    serverOverviewElement: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
    },
    serverOverviewElementText: {
        minWidth: 149,
        paddingRight: theme.spacing(2),
    },
    serverOverviewElementChartWrapper: {
        width: '100%',
    },
    mainChartBody: {
        overflowX: 'auto',
    },
    mainChartHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            flexWrap: 'wrap',
        },
    },
    mainChartHeaderLabels: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            order: 3,
            width: '100%',
            justifyContent: 'center',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
    },
    mainChartHeaderLabel: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
    },
    mainChartSelectRoot: {
        borderColor: theme.palette.text.hint + '80 !important',
    },
    mainChartSelect: {
        padding: 10,
        paddingRight: 25,
    },
    mainChartLegentElement: {
        fontSize: '14px !important',
        marginLeft: theme.spacing(1),
    },
    table: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiTableHead-root': {
                display: 'none',
            },

            '& .MuiTableRow-root td:first-child': {
                backgroundColor: tinycolor(theme.palette.background.dark),
            },

            '& .MuiTableCell-root': {
                display: 'flex',
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingRight: 0,
                '& .MuiIconButton-root': {
                    padding: 0,
                },
            },

            '& .MuiTableCell-root::before': {
                content: 'attr(label)',
                fontWeight: 'bold',
                width: '120px',
                minWidth: '120px',
            },
        },
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    fixIconRight: {
        '& .MuiSelect-icon': {
            right: 4,
        },
    },
    actionsIcon: {
        color: '#76767B',
    },

    // Order view

    openLinkButton: {
        marginRight: theme.spacing(2),
        // marginRight: theme.spacing(4), // for access token label
        color: theme.palette.success.contrastText,
    },

    copyLinkButton: {
        marginLeft: theme.spacing(2),
    },

    text: {
        overflowWrap: 'break-word',
    },
}));
