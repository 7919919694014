import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { orderStatuses } from './helpers/statuses';

// styles
import useStyles from './styles';

// components
import { Button, Typography } from '../../components/Wrappers/Wrappers';
import Widget from '../../components/Widget/Widget';

// context
import { useOrderDispatch, useOrderState, actions as orderActions } from '../../context/OrderContext';

const EditOrder = () => {
    const classes = useStyles();

    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();

    const history = useHistory();
    const location = useLocation();

    const [updatedOrderValues, setUpdatedOrderValues] = useState(null);

    const backToViewOrder = () => {
        const { orderId, practiceId } = orderState.currentOrder;
        history.push(`view?practiceId=${practiceId}&orderId=${orderId}`);
    };

    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const practiceId = queryString.get('practiceId');
        const orderId = queryString.get('orderId');
        if (!practiceId || !orderId) {
            toast.error('Unable to find practice id or order id');
        } else if (!orderState.currentOrder || orderState.currentOrder.orderId !== orderId) {
            orderActions.doFind(practiceId, orderId)(orderDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        if (orderState.orderUpdated) backToViewOrder();
    }, [orderState.orderUpdated]); //eslint-disable-line

    const handleTextFieldChange = (e) => {
        setUpdatedOrderValues({ ...updatedOrderValues, [e.target.name]: e.target.value });
    };

    const handleClickCancel = () => backToViewOrder();

    const handleClickSave = () => {
        const { practiceId } = orderState.currentOrder;
        const data = { ...updatedOrderValues, practiceId };
        orderActions.doUpdate(orderState.currentOrder.orderId, data)(orderDispatch);
    };

    const Footer = () => (
        <Box display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
            <Button onClick={handleClickCancel} variant="outlined" color="secondary">
                Cancel
            </Button>
            <Button onClick={handleClickSave} variant="contained" color="secondary">
                Save
            </Button>
        </Box>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={600}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 30 }}>
                                Edit Order
                            </Typography>

                            <Grid container direction="column" spacing={3}>
                                {orderState?.findLoading || orderState?.saveLoading ? (
                                    <CircularProgress color="secondary" className={classes.progressCentered} />
                                ) : (
                                    <>
                                        <FormControl
                                            key="status-form"
                                            variant="outlined"
                                            onChange={handleTextFieldChange}
                                            style={{ marginBottom: 35 }}
                                        >
                                            <InputLabel id="status-label" required>
                                                Select a status
                                            </InputLabel>
                                            <Select
                                                labelId="status-label"
                                                id="status"
                                                value={
                                                    updatedOrderValues?.orderStatus ||
                                                    orderState?.currentOrder?.orderStatus
                                                }
                                                name="orderStatus"
                                                onChange={handleTextFieldChange}
                                                label="Order Status"
                                            >
                                                {Object.values(orderStatuses)
                                                    .filter((s) => s.length)
                                                    .map((status) => (
                                                        <MenuItem key={status} value={status}>
                                                            {status}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            id="comments"
                                            label="Comments"
                                            onChange={handleTextFieldChange}
                                            value={updatedOrderValues?.comments || orderState?.currentOrder?.comments}
                                            name="comments"
                                            multiline
                                            minRows="5"
                                            margin="normal"
                                            variant="outlined"
                                            style={{ marginBottom: 35 }}
                                            helperText="Comments on the order are not visible by the practice"
                                        />
                                    </>
                                )}
                            </Grid>
                            <Footer />
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default EditOrder;
