import React from 'react'
import { TableCell, CircularProgress, TableRow } from '@material-ui/core'
import { Typography } from '../../components/Wrappers'
import PropTypes from 'prop-types'

const EmptyTableRow = ({ nbColumns, isLoading, label }) => (
    <TableRow key="no-data-row" style={{ height: 150 }}>
        <TableCell colSpan={nbColumns} align="center" style={{ textAlign: 'center' }}>
            {isLoading ? (
                <CircularProgress color="primary" />
            ) : (
                <Typography variant="h6" color="secondary">
                    {label}
                </Typography>
            )}
        </TableCell>
    </TableRow>
)

EmptyTableRow.propTypes = {
    nbColumns: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
}

export default EmptyTableRow
