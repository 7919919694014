import React, { useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

// form attributes
import { caseDesignAttributes } from '../helpers/form';
import { Typography } from '../../../components/Wrappers/Wrappers';

const TreatmentTypeNote = () => (
    <>
        <Typography weight="bold" style={{ marginBottom: 5 }}>
            Note: Treatment type change
        </Typography>
        <Typography style={{ marginBottom: 35 }}>
            No additional charge if treatment type remains the same. However, an additional charge of $300 will occur if
            treatment type is upgraded from 3-3 or 5-5 to 7-7.
        </Typography>
    </>
);

const CaseDesign = ({ newOrder, allowFullArchTreatment, formErrors, onTextFieldChange }) => {
    useEffect(() => {
        // reset additional 7 to 7 attributes if treatment type changes
        if (!newOrder.treatmentType.match('7 to 7')) {
            newOrder.posteriorCrossbite = '';
            newOrder.anteriorPosteriorRelation = '';
        }
    }, [newOrder.treatmentType]); // eslint-disable-line

    return (
        <>
            {newOrder.refinement === 'Yes' && <TreatmentTypeNote />}

            {caseDesignAttributes(newOrder, allowFullArchTreatment).map((attr) => (
                <FormControl key={attr.id} variant="outlined" onChange={onTextFieldChange} style={{ marginBottom: 35 }}>
                    <InputLabel id={`${attr.id}-label`} required={attr.required}>
                        {attr.label}
                    </InputLabel>

                    <Select
                        labelId={`${attr.id}-label`}
                        id={attr.id}
                        value={newOrder[`${attr.id}`]}
                        name={attr.id}
                        onChange={onTextFieldChange}
                        label={attr.label}
                        error={formErrors[attr.id]}
                    >
                        {attr.items.map((item) => (
                            <MenuItem key={item.value || item} value={item.value || item}>
                                {item.displayValue || item}
                            </MenuItem>
                        ))}
                    </Select>
                    {attr.helperText && <FormHelperText>{attr.helperText}</FormHelperText>}
                </FormControl>
            ))}

            {newOrder.provideTreatmentPlan !== 'Yes' && (
                <TextField
                    id="instructions"
                    label="Summarise Treatment Goal"
                    onChange={onTextFieldChange}
                    value={newOrder.instructions || ''}
                    name="instructions"
                    multiline
                    minRows="5"
                    margin="normal"
                    variant="outlined"
                    style={{ marginBottom: 35 }}
                    helperText="Please indicate if the patient has crowns, veneers, missing teeth (including their position) or other important information"
                />
            )}
        </>
    );
};

export default CaseDesign;
