import React, { useEffect } from 'react';
import { Grid, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from './styles';

// components
import { Typography, Button } from '../../components/Wrappers';

// context
import { usePracticeDispatch, usePracticeState, actions } from '../../context/PracticeContext';

const Dashboard = () => {
    const classes = useStyles();
    const history = useHistory();
    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();

    // Fetch Practices here to get the list as soon as possible as it is needed in most workflows
    useEffect(() => {
        if (!practiceState.practices) {
            actions.doFetch()(practiceDispatch);
        }
    }, []); // eslint-disable-line

    const CreateOrderCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Create Order" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Upload the 3D scan files of a patient and submit a new order to request a treatment
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" onClick={() => history.push('/app/order/add')}>
                    Create
                </Button>
            </CardActions>
        </Card>
    );

    const ViewOrdersCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="View Orders" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    View a list of the recent orders, check their status and see the details for each one
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => history.push('/app/order/list?orderStatus=in-progress')}
                >
                    View
                </Button>
            </CardActions>
        </Card>
    );

    const CreatePracticeCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Create Practice" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Create an account for a new practice
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" onClick={() => history.push('/app/practice/add')}>
                    Create
                </Button>
            </CardActions>
        </Card>
    );

    const ViewPracticesCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="View Practices" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    View a list of the practices account
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" onClick={() => history.push('/app/practice/list')}>
                    View
                </Button>
            </CardActions>
        </Card>
    );

    const CreateRefinementCard = () => (
        <Card className={classes.cardMedia}>
            <CardHeader title="Create Refinement" />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    View a list of the complete orders and select the one one you want to refine
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => history.push('/app/order/list?orderStatus=complete')}
                >
                    Create
                </Button>
            </CardActions>
        </Card>
    );

    const EmptyCard = () => <Card className={classes.cardMediaHidden} />;

    return (
        <>
            <Grid item justifyContent="center" container>
                <CreateOrderCard />
                <ViewOrdersCard />
            </Grid>
            <Grid item justifyContent="center" container>
                <CreatePracticeCard />
                <ViewPracticesCard />
            </Grid>
            <Grid item justifyContent="center" container>
                <CreateRefinementCard />
                <EmptyCard />
            </Grid>
        </>
    );
};

export default Dashboard;
