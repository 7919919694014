import React, { useState, useEffect } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { capitalCase } from 'change-case';

// components
import { Button } from '../../components/Wrappers/Wrappers';
import { Section } from '../../components/Section/Section';
import Widget from '../../components/Widget/Widget';

// styles
import useStyles from './styles';

// context
import { usePracticeDispatch, usePracticeState, actions } from '../../context/PracticeContext';
import { useUserState } from '../../context/UserContext';

// form attributes
import { practiceAttributes } from './form-attributes';

const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// return true if the the error fields contains errors
const hasMissingRequiredFields = (errorFields) => Object.values(errorFields).filter((x) => !!x).length !== 0;

// find the required fields that are missing
const findMissingRequiredFields = (order) => {
    const requiredAttributes = practiceAttributes.filter((el) => el.required);
    const requiredFieldErrors = requiredAttributes.reduce((errors, attr) => {
        errors[attr.id] = !order[attr.id];
        return errors;
    }, {});
    return requiredFieldErrors;
};

const emptyPractice = {
    name: '',
    email: '',
    group: 'dental-practice',
    territory: '',
    manufacturer: 'overseas-lab',
    address: '',
    postcode: '',
    state: '',
    practiceManager: '',
    phoneNumber: '',
    allowFullArchTreatment: '',
    scanOrImpression: '',
    scannerBrand: '',
    clearAlignerBrand: '',
    clearAlignerPerMonth: '',
};

const AddPractice = () => {
    const [newPractice, setNewPractice] = useState(emptyPractice);
    const [error, setError] = useState({});

    const classes = useStyles();
    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();
    const userState = useUserState();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        actions.doNewForm()(practiceDispatch);
    }, []); // eslint-disable-line

    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const practiceId = queryString.get('practiceId');
        if (practiceId) {
            actions.doFind(practiceId)(practiceDispatch);
        } else {
            actions.doNewForm()(practiceDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        setNewPractice(practiceState.currentPractice || { ...emptyPractice, territory: userState?.territory || '' });
    }, [practiceState.currentPractice]); // eslint-disable-line

    const handleChange = (e) => {
        if (e.target.required) {
            setError({
                ...error,
                [e.target.name]: !e.target.value,
            });
        }
        setNewPractice({
            ...newPractice,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        const requiredFieldErrors = findMissingRequiredFields(newPractice);
        if (hasMissingRequiredFields(requiredFieldErrors)) {
            setError({ ...error, ...requiredFieldErrors });
        } else if (!emailIsValid(newPractice.email)) {
            toast.error('Invalid email address format');
        } else {
            doSubmit(newPractice);
        }
    };

    const doSubmit = async (data) => {
        if (data.practiceId) {
            await actions.doUpdate(data.practiceId, data)(practiceDispatch, history);
        } else {
            await actions.doCreate(data)(practiceDispatch, history);
        }
    };

    const renderTextField = (attribute) => {
        const isTextArea = attribute.type === 'textArea';
        return (
            <TextField
                id={attribute.id}
                key={attribute.id}
                label={attribute.label}
                onChange={handleChange}
                name={attribute.id}
                value={newPractice[attribute.id] || ''}
                type={attribute.type || 'text'}
                multiline={isTextArea}
                minRows={isTextArea ? '5' : '1'}
                variant="outlined"
                style={{ marginBottom: 35 }}
                disabled={newPractice.practiceId && new RegExp('email').test(attribute.id)}
                required={attribute.required}
                error={error[attribute.id]}
            />
        );
    };

    const renderFormControl = (attribute) => (
        <FormControl key={attribute.id} variant="outlined" onChange={handleChange} style={{ marginBottom: 35 }}>
            <InputLabel id="group-label" required={attribute.required}>
                {attribute.label}
            </InputLabel>
            <Select
                labelId={`${attribute.id}-label`}
                id={attribute.id}
                value={newPractice[attribute.id]}
                name={attribute.id}
                onChange={handleChange}
                label={attribute.label}
                error={error[attribute.id]}
            >
                {attribute.items.map((item) => (
                    <MenuItem key={item} value={item}>
                        {attribute.id.match('state|territory') ? item : capitalCase(item)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const practiceDetails = (attr) => {
        if (attr.type === 'section') {
            return <Section key={attr.label} title={attr.label} />;
        } else if (attr.items) {
            return renderFormControl(attr);
        } else {
            return renderTextField(attr);
        }
    };

    const formFooter = () => (
        <div>
            <div>
                <Box display="flex" justifyContent="center">
                    {practiceState.saveLoading ? (
                        <CircularProgress color="secondary" className={classes.progressCentered} />
                    ) : (
                        <Button variant="contained" color="secondary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    )}
                </Box>
            </div>
        </div>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={600}>
                            {practiceAttributes.map(practiceDetails)}
                            {formFooter()}
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default AddPractice;
