import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

// components
import { Typography } from '../../../components/Wrappers/Wrappers'

// styles
import useStyles from '../styles'

const TreatmentPlan = ({ newOrder, needTreatmentPlanClicked }) => {
    const classes = useStyles()

    return (
        <>
            <Card
                className={
                    newOrder.provideTreatmentPlan === 'No'
                        ? classes.treatmentPlanCardClicked
                        : classes.treatmentPlanCard
                }
                onClick={needTreatmentPlanClicked('No')}
            >
                <CardContent>
                    <Typography variant="h6" weight="bold" style={{ marginBottom: 15 }}>
                        I need Smile Academy to design my treatment plan (recommended)
                    </Typography>
                    <Typography style={{ marginBottom: 5 }}>
                        Smile Academy will design my patient’s treatment plan.
                    </Typography>
                    <Typography>(Including smile summary and Doctor & Patient 3D Viewer)</Typography>
                </CardContent>
            </Card>

            <Card
                className={
                    newOrder.provideTreatmentPlan === 'Yes'
                        ? classes.treatmentPlanCardClicked
                        : classes.treatmentPlanCard
                }
                onClick={needTreatmentPlanClicked('Yes')}
            >
                <CardContent>
                    <Typography variant="h6" weight="bold" style={{ marginBottom: 15 }}>
                        I design my own treatment plan
                    </Typography>
                    <Typography style={{ marginBottom: 5 }}>
                        I have my own CAD/CAM software to design orthodontic treatment plan for clear aligners and
                        choose to upload my own STL files.
                    </Typography>
                    <Typography>
                        (Files needs to be compressed into 1 single ZIP file) -{' '}
                        <span className={classes.boldText} weight="bold">
                            Design fee waived
                        </span>
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default TreatmentPlan
