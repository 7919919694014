import React, { useEffect, useState } from 'react';
import {
    Chip as ChipBase,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Search as SearchIcon, AttachMoney as PaidIcon, Warning as WarningIcon } from '@material-ui/icons';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import { RemoveRedEye as ViewIcon, PlayCircleOutline as PlayIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Widget from '../../components/Widget';
import EnhancedTableHead from '../../components/Table/EnhancedTableHead';
import EmptyTableRow from '../../components/Table/EmptyTableRow';
import { Chip, Typography } from '../../components/Wrappers';

// context
import { useOrderDispatch, useOrderState, actions as orderActions } from '../../context/OrderContext';
import { usePracticeDispatch, usePracticeState, actions as practiceActions } from '../../context/PracticeContext';

// helpers
import { stableSort, getSorting, getPracticeTradingName } from '../../common/helpers';
import { colorForStatus, orderStatuses } from './helpers/statuses';
import SortTableSelect from '../../components/Table/SortTableSelect';

const headCells = [
    { id: 'tradingName', alignLeft: true, disablePadding: false, label: 'Trading Name', isSortable: false },
    { id: 'territory', alignLeft: true, disablePadding: false, label: 'Territory', isSortable: false },
    { id: 'treatmentType', alignLeft: true, disablePadding: false, label: 'Treatment Type', isSortable: false },
    { id: 'prescriberName', alignLeft: true, disablePadding: false, label: 'Prescriber', isSortable: true },
    { id: 'patientName', alignLeft: true, disablePadding: false, label: 'Patient', isSortable: true },
    { id: 'createdAt', alignLeft: true, disablePadding: false, label: 'Date of creation', isSortable: true },
    { id: 'caseNumber', alignLeft: true, disablePadding: false, label: 'Case number', isSortable: false },
    { id: 'serialNumber', alignLeft: true, disablePadding: false, label: 'Serial number', isSortable: false },
    { id: 'status', alignLeft: true, disablePadding: false, label: 'Status', isSortable: false },
    { id: 'dm', alignLeft: true, disablePadding: false, label: 'Dm', isSortable: false },
    { id: 'refinement', alignLeft: true, disablePadding: false, label: 'Refinement', isSortable: false },
    { id: 'action', alignLeft: true, disablePadding: false, label: 'Action', isSortable: false },
];

/**
 * Collect the N to N details from the treatment type
 * @param {*} treatmentType: Treatment From Canine to Canine (3 to 3)
 * @returns 3 to 3
 */
const getShortTreatmentType = (treatmentType) => treatmentType.match(/\d to \d/);

/**
 * Returns orders matching the search input
 */
const filterItems = (orders, practices, input) => {
    var regexp = new RegExp(input, 'gi');
    return orders.filter((o) => {
        const treatmentType = getShortTreatmentType(o.treatmentType);
        const practiceTradingName = getPracticeTradingName(practices, o.practiceId);
        return (
            (treatmentType && treatmentType[0]?.match(regexp)) ||
            practiceTradingName?.match(regexp) ||
            o.patientName?.match(regexp) ||
            o.prescriberName?.match(regexp) ||
            o.caseNumber?.match(regexp) ||
            o.serialNumber?.match(regexp) ||
            o.orderStatus?.match(regexp) ||
            o.territory?.match(regexp)
        );
    });
};

const OrderTableRow = ({ classes, headers, row, practices, onViewOrder, onFinalizeOrder }) => (
    <TableRow hover tabIndex={-1}>
        <TableCell label={headers[0].label}>{getPracticeTradingName(practices, row.practiceId)}</TableCell>
        <TableCell label={headers[1].label}>{row.territory}</TableCell>
        <TableCell label={headers[2].label}>{getShortTreatmentType(row.treatmentType)}</TableCell>
        <TableCell label={headers[3].label}>{row.prescriberName}</TableCell>
        <TableCell label={headers[4].label}>
            <Typography style={{ whiteSpace: 'nowrap' }}>{row.patientName}</Typography>
        </TableCell>
        <TableCell label={headers[5].label}>
            {row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : ''}
        </TableCell>
        <TableCell label={headers[6].label}>{row.caseNumber}</TableCell>
        <TableCell label={headers[7].label}>{row.serialNumber}</TableCell>
        <TableCell label={headers[8].label} style={{ whiteSpace: 'nowrap' }}>
            <Chip label={row.orderStatus || 'Unknown'} color={colorForStatus(row.orderStatus)} />
            {row.isPaid && (
                <IconButton>
                    <PaidIcon color="primary" />
                </IconButton>
            )}
            {row.orderStatus === orderStatuses.CASE_NEEDS_APPROVAL && row.treatmentInvoiced && (
                <Tooltip
                    title={
                        <Typography fontSize={30}>
                            Case pending for over 2 months. Treatment plan only has been invoiced and will be deducted
                            from total course cost when case is approved.
                        </Typography>
                    }
                >
                    <IconButton>
                        <WarningIcon color="primary" />
                    </IconButton>
                </Tooltip>
            )}
        </TableCell>
        <TableCell label={headers[9].label}>
            {row.dentalMonitoring === 'Yes' ? <strong className={classes.dentalMonitoring}>DM</strong> : ''}
        </TableCell>
        <TableCell label={headers[10].label}>{row.refinement === 'Yes' ? 'Yes' : ''}</TableCell>
        <TableCell label={headers[11].label} align="left" style={{ whiteSpace: 'nowrap' }}>
            <IconButton
                className={classes.actionsIcon}
                aria-owns="actions-menu"
                aria-haspopup="true"
                onClick={onViewOrder}
            >
                <ViewIcon color="primary" />
            </IconButton>
            {row.orderStatus === orderStatuses.WAITING_FOR_IMPRESSION && (
                <IconButton
                    className={classes.actionsIcon}
                    aria-owns="actions-menu"
                    aria-haspopup="true"
                    onClick={onFinalizeOrder}
                >
                    <PlayIcon color="primary" />
                </IconButton>
            )}
        </TableCell>
    </TableRow>
);

OrderTableRow.propTypes = {
    classes: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired,
    practices: PropTypes.array.isRequired,
    onViewOrder: PropTypes.func.isRequired,
    onFinalizeOrder: PropTypes.func.isRequired,
};

const EnhancedTableToolbar = ({
    classes,
    onSearchInputChange,
    onOrderStatusChange,
    onRequestSort,
    searchValue,
    headers,
    order,
    orderBy,
}) => {
    const displaySortTableSelect = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        <Toolbar style={{ marginTop: 10, marginBottom: 10 }}>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2} style={{ display: 'flex', gap: 8 }}>
                    <ChipBase
                        label="Cases in progress"
                        onClick={onOrderStatusChange('in-progress')}
                        className={classNames(classes.orderStatusChip, {
                            active: searchValue.orderStatus === 'in-progress',
                        })}
                    />
                    <ChipBase
                        label="Cases completed"
                        onClick={onOrderStatusChange('complete')}
                        className={classNames(classes.orderStatusChip, {
                            active: searchValue.orderStatus === 'complete',
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                    <TextField
                        color="secondary"
                        type="search"
                        placeholder="Search"
                        fullWidth
                        onChange={onSearchInputChange}
                        value={searchValue.searchInput || ''}
                    />
                </Grid>
                {displaySortTableSelect && (
                    <Grid item xs={12} md={4} style={{ display: 'flex', marginBottom: 10 }}>
                        <SortTableSelect
                            headers={headers}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={onRequestSort}
                        ></SortTableSelect>
                    </Grid>
                )}
            </Grid>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    onSearchInputChange: PropTypes.func.isRequired,
    onOrderStatusChange: PropTypes.func.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    searchValue: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const OrderList = () => {
    const classes = useStyles();
    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();
    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();
    const history = useHistory();
    const location = useLocation();

    // order and page preferences
    const { order, orderBy, rowsPerPage, page } = orderState.listPreferences;

    // Search feature
    const [filteredItems, setFilteredItems] = useState([]);

    // =================== Hooks ======================

    useEffect(() => {
        if (!practiceState.practices) {
            practiceActions.doFetch()(practiceDispatch);
        }
    }, []); // eslint-disable-line

    /**
     * Check the query string to set the order status filter
     */
    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const orderStatus = queryString.get('orderStatus');
        const currentStatusFilter = orderState.search.orderStatus;
        if (orderStatus?.match('in-progress|complete') && orderStatus !== currentStatusFilter) {
            // the orderStatus filter must be updated
            orderActions.doResetState()(orderDispatch);
            const search = { searchInput: '', orderStatus };
            orderActions.doSearchOrders(search)(orderDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        setFilteredItems(orderState.orders?.items || []);
    }, [orderState.orders]); // eslint-disable-line

    /**
     * Fetch orders when order status changes
     * The state is reset on status change so only fetch when no order is found
     */
    useEffect(() => {
        if (orderState.search.orderStatus && !orderState.orders) {
            orderActions.doFetch({ orderStatus: orderState.search.orderStatus })(orderDispatch);
        }
    }, [orderState.search.orderStatus]); // eslint-disable-line

    useEffect(() => {
        const input = orderState.search.searchInput || '';
        const orders = orderState.orders?.items || [];
        if (input.length === 0) {
            setFilteredItems(orders);
        } else if (input.length > 2) {
            const practices = practiceState.practices?.items || [];
            setFilteredItems(filterItems(orders, practices, input));
        }
    }, [orderState.search.searchInput]); // eslint-disable-line

    // =================== Handlers ======================

    const handleRequestSort = (_, property) => {
        const isDesc = orderBy === property && order === 'desc';
        const preferences = { ...orderState.listPreferences, order: isDesc ? 'asc' : 'desc', orderBy: property };
        orderActions.doChangeListPreferences(preferences)(orderDispatch);
    };

    const handleChangePage = (_, newPage) => {
        const preferences = { ...orderState.listPreferences, page: newPage };
        orderActions.doChangeListPreferences(preferences)(orderDispatch);
    };

    const handleChangeRowsPerPage = (event) => {
        const preferences = { ...orderState.listPreferences, rowsPerPage: parseInt(event.target.value, 10), page: 0 };
        orderActions.doChangeListPreferences(preferences)(orderDispatch);
    };

    const handleSearchInputChange = (e) => {
        const search = { ...orderState.search, searchInput: e.target.value };
        orderActions.doSearchOrders(search)(orderDispatch);
    };

    const handleOrderStatusFilter = (newStatus) => () => {
        orderActions.doResetState()(orderDispatch);
        const search = { searchInput: '', orderStatus: newStatus };
        orderActions.doSearchOrders(search)(orderDispatch);
        // update the query string in the url according to the orderStatus filter value
        history.replace(`list?orderStatus=${newStatus}`);
    };

    const handleViewOrder =
        ({ practiceId, orderId }) =>
        () =>
            history.push(`view?practiceId=${practiceId}&orderId=${orderId}`);

    const handleFinalizeOrder =
        ({ practiceId, orderId }) =>
        () =>
            history.push(`add?practiceId=${practiceId}&orderId=${orderId}`);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget noBodyPadding bodyClass={classes.tableWidget}>
                    <EnhancedTableToolbar
                        classes={classes}
                        onSearchInputChange={handleSearchInputChange}
                        onOrderStatusChange={handleOrderStatusFilter}
                        onRequestSort={handleRequestSort}
                        searchValue={orderState.search}
                        headers={headCells}
                        order={order}
                        orderBy={orderBy}
                    />

                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="recent orders">
                            <EnhancedTableHead
                                classes={classes}
                                headers={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={filteredItems.length}
                            />

                            <TableBody>
                                {(orderState.fetchLoading || filteredItems?.length === 0) && (
                                    <EmptyTableRow
                                        nbColumns={headCells.length}
                                        isLoading={orderState.fetchLoading}
                                        label="No order found"
                                    />
                                )}

                                {!orderState.fetchLoading &&
                                    filteredItems?.length > 0 &&
                                    stableSort(filteredItems, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <OrderTableRow
                                                key={row.orderId}
                                                classes={classes}
                                                headers={headCells}
                                                row={row}
                                                practices={practiceState.practices?.items || []}
                                                onViewOrder={handleViewOrder(row)}
                                                onFinalizeOrder={handleFinalizeOrder(row)}
                                            />
                                        ))}
                            </TableBody>
                        </Table>
                    </div>

                    <TablePagination
                        rowsPerPageOptions={[50, 100]}
                        component="div"
                        count={filteredItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'previous page' }}
                        nextIconButtonProps={{ 'aria-label': 'next page' }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Widget>
            </Grid>
        </Grid>
    );
};

export default OrderList;
