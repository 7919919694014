import React from 'react'
import { Divider } from '@material-ui/core'
import { Typography } from '../Wrappers/Wrappers'

export const Section = ({ title }) => (
    <>
        <Typography weight="bold" style={{ marginBottom: 5 }}>
            {title}
        </Typography>
        <Divider style={{ marginBottom: 25 }} />
    </>
)
