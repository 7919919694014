export const mockUser = {
    username: '76503934-53b3-48ce-b7f8-7da6ca537876',
    pool: {
        userPoolId: 'ap-southeast-2_abcdef',
        clientId: 'jkhde76edg76weg',
    },
    signInUserSession: {
        idToken: {
            jwtToken: 'an_id_token',
            payload: {
                sub: '76503934-53b3-48ce-b7f8-7da6ca537876',
                token_use: 'id',
                'cognito:groups': ['dental-practice'],
            },
        },
        refreshToken: {
            token: 'a_refresh_token',
        },
        accessToken: {
            jwtToken: 'a_jwt_token',
            payload: {
                sub: '76503934-53b3-48ce-b7f8-7da6ca537876',
                token_use: 'access',
                'cognito:groups': ['dental-practice'],
            },
        },
    },
    authenticationFlowType: 'USER_SRP_AUTH',
    attributes: {
        sub: '76503934-53b3-48ce-b7f8-7da6ca537876',
        email_verified: true,
        name: 'Carina Heights Dental',
        email: 'admin@smile-academy.com.au',
    },
};

export const mockOrder = {
    status: 'Needs Approval',
    createdAt: 1598924883029,
    orderId: 'db6e49a0-ec48-11ea-9c56-a3c6b024487e',
    prescriberName: 'Dr John Doe',
    patientName: 'Chris James',
    instructions: 'Instructions for the case',
    trimLine: 'Straight cut at the gingival zenith margin',
    allowIPR: 'Yes',
    allowAttachment: 'No',
    upperMidline: 'Move right',
    lowerMidline: 'Maintain',
    anteriorLeveling: 'Level By Incisal Edges',
    treatmentType: 'Treat 5 to 5',
    links: [
        {
            url: 'http://www.google.com.au',
            type: 'approval',
            token: '123',
        },
        {
            url: 'http://www.apple.com.au',
            type: 'patient',
            token: '456',
        },
    ],
};

export const mockFileUpload = {
    signed_link: 'http://mocked_presigned_url',
    key: 'mocked_filekey',
    original_filename: 'mocked_filename',
};

export const mockOrdersList = {
    count: 7,
    items: [
        {
            createdAt: 1598924883029,
            orderId: '2c427850-ebf5-11ea-91aa-31381756c164',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-114803',
            treatmentType: 'Treat 5 to 5',
            status: 'Delivered',
        },
        {
            createdAt: 1598402620082,
            orderId: '2f490920-e735-11ea-9477-5bbfd8711a3e',
            patientName: 'Emilien Perico',
            prescriberName: 'Dr John Doe',
            treatmentType: 'Treat 5 to 5',
            serialNumber: '200902-101204',
            status: 'Delivered',
        },
        {
            createdAt: 1598959884704,
            orderId: 'aae23a00-ec46-11ea-a63e-b5661439f3a8',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-213124',
            treatmentType: 'Treat 5 to 5',
            status: 'Pending',
        },
        {
            createdAt: 1598923863133,
            orderId: 'cc5ab0d0-ebf2-11ea-8c4c-67334c09cf5a',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-113103',
            treatmentType: 'Treat 5 to 5',
            status: 'Canceled',
        },
        {
            createdAt: 1598960825146,
            orderId: 'db6e49a0-ec48-11ea-9c56-a3c6b024487e',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-214705',
            treatmentType: 'Treat 5 to 5',
            status: 'Pending',
        },
        {
            createdAt: 1598924326573,
            orderId: 'e09605d0-ebf3-11ea-900d-99cf845304ad',
            patientName: 'Chris James',
            prescriberName: 'Dr John Doe',
            serialNumber: '200901-113846',
            treatmentType: 'Treat 5 to 5',
            status: 'Delivered',
        },
        {
            createdAt: 1598512046446,
            orderId: 'f67b18e0-e833-11ea-b59e-c32854a53708',
            patientName: 'Emilien Perico',
            prescriberName: 'Dr Ben Smith',
            serialNumber: '200827-170726',
            treatmentType: 'Treat 3 to 3',
            status: 'In a process',
        },
    ],
};

export const mockPractice = {
    practiceId: '81f08414-fa0b-471a-b5f3-fb4e13a3e0f5',
    name: 'Carina Height Dental',
    email: 'admin@carina-dental.com.au',
    createdAt: 1600856317049,
    group: 'dental-practice',
    manufacturer: 'brisbane-lab',
    phoneNumber: '0987654312',
    address: '12 brisbane street',
    postcode: '4000',
    state: 'QLD',
    practiceManager: 'John Doe',
    scanOrImpression: 'Scan',
    scannerBrand: 'GreatScan',
    clearAlignerBrand: 'ClearBlue',
    clearAlignerPerMonth: '12',
};

export const mockPracticesList = {
    items: [
        {
            createdAt: 1600838774620,
            group: 'ortho-practice',
            email: 'emilien.perico+7@gmail.com',
            practiceId: '470f831d-c47f-4c8b-8ece-355c7fa30c28',
            name: 'Lutwyche Dentists',
        },
        {
            createdAt: 1600759033415,
            group: 'dental-practice',
            email: 'emilien.perico+4@gmail.com',
            practiceId: 'afe70f8a-240a-4171-8913-3d813a48173e',
            name: 'Lutwyche Dentists',
        },
        {
            createdAt: 1600563837360,
            group: 'beauty-salon',
            email: 'emilienp+2@gmail.com',
            practiceId: 'df7018bf-71d1-44ff-b980-16fd460e6b7b',
            name: 'Pratice 6',
        },
        {
            createdAt: 1600761158317,
            group: 'dental-practice',
            email: 'emilien.perico+6@gmail.com',
            practiceId: '48847e42-9ac0-43fb-98dd-1083fe8deea1',
            name: 'Lutwyche Dentists',
        },
        {
            createdAt: 1600760302944,
            group: 'dental-practice',
            email: 'emilien.perico+5@gmail.com',
            practiceId: 'a125106d-a6ae-4b92-b5b5-61e27a44be4c',
            name: 'Lutwyche Dentists',
        },
        {
            createdAt: 1600734421535,
            group: 'dental-practice',
            email: 'emilien.perico+3@gmail.com',
            practiceId: 'cecac5e8-3cbc-499e-a1d7-03f3ea69e11c',
            name: 'North Side Dental',
        },
        {
            createdAt: 1600563711083,
            group: 'dental-practice',
            email: 'emilienp+1@gmail.com',
            practiceId: '48cbf404-aed0-4bb3-b4d6-83c2b8387f4d',
            name: 'Pratice 6',
        },
    ],
    count: 7,
};
