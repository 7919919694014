import tinycolor from 'tinycolor2';

const navy = '#1B154A';
const poppy = '#ED3378';
const pumpkin = '#F79321';
const coral = '#F16567';

const primary = coral;
const secondary = navy;
const tertiary = poppy;
const quaternary = pumpkin;

const warning = '#FFC260';
const success = '#3CD4A0';
const info = '#9013FE';

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
    palette: {
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#ccc',
        },
        tertiary: {
            main: tertiary,
            light: tinycolor(tertiary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(tertiary)
                .darken(darkenRate)
                .toHexString(),
        },
        quaternary: {
            main: quaternary,
            light: tinycolor(quaternary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(quaternary)
                .darken(darkenRate)
                .toHexString(),
        },
        warning: {
            main: warning,
            light: tinycolor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(warning)
                .darken(darkenRate)
                .toHexString(),
        },
        success: {
            main: success,
            light: tinycolor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(success)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#fff',
        },
        info: {
            main: info,
            light: tinycolor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(info)
                .darken(darkenRate)
                .toHexString(),
        },
        inherit: {
            main: 'inherit',
            light: tinycolor('inherit')
                .lighten('inherit')
                .toHexString(),
            dark: tinycolor('inherit')
                .darken('inherit')
                .toHexString(),
        },
        text: {
            primary: '#4A4A4A',
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
        },
        background: {
            default: '#F6F7FF',
            light: '#F3F5FF',
            dark: '#E9EAF2',
        },
    },
    customShadows: {
        widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
};

export default defaultTheme;
