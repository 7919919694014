import React from 'react'

// components
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@material-ui/core'
import { Typography } from '../../../components/Wrappers/Wrappers'
import { Section } from '../../../components/Section/Section'

//config
import config from '../../../config'

import { invoicingRecipientAttributes, deliveryAddressAttributes } from '../helpers/form'

export const defaultOption = 'default'
export const otherOption = 'other'

const DeliveryTextField = ({ newOrder, formErrors, attribute, onChange }) => (
    <TextField
        id={attribute.id}
        label={attribute.label}
        onChange={onChange}
        name={attribute.id}
        value={newOrder[attribute.id] || ''}
        type={attribute.type || 'text'}
        required={attribute.required}
        variant="outlined"
        style={{ marginBottom: 35 }}
        error={formErrors[attribute.id]}
    />
)

const DeliveryFormControl = ({ newOrder, formErrors, attribute, onChange }) => (
    <FormControl key={attribute.id} variant="outlined" onChange={onChange} style={{ marginBottom: 35 }}>
        <InputLabel id="group-label" required={attribute.required}>
            {attribute.label}
        </InputLabel>
        <Select
            labelId={`${attribute.id}-label`}
            id={attribute.id}
            value={newOrder[attribute.id]}
            name={attribute.id}
            onChange={onChange}
            label={attribute.label}
            error={formErrors[attribute.id]}
        >
            {attribute.items.map(item => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)

const InvoicingSection = ({ newOrder, practice, formErrors, onTextFieldChange }) => {
    const invoicingRecipient = newOrder.orderId ? newOrder.invoicingRecipient : practice?.name
    return (
        <>
            <Section title="Invoicing" />
            <FormControl style={{ marginBottom: 15 }}>
                <Typography style={{ marginBottom: 10 }}>Select the practice recipient for your invoice</Typography>
                <RadioGroup name="invoicingOption" value={newOrder.invoicingOption} onChange={onTextFieldChange}>
                    <FormControlLabel value={defaultOption} control={<Radio />} label={invoicingRecipient} />
                    <FormControlLabel value={otherOption} control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>

            {newOrder.invoicingOption === otherOption &&
                invoicingRecipientAttributes.map(attr => (
                    <DeliveryTextField
                        key={attr.id}
                        newOrder={newOrder}
                        formErrors={formErrors}
                        attribute={attr}
                        onChange={onTextFieldChange}
                    />
                ))}
        </>
    )
}

const DeliverySection = ({ newOrder, practice, formErrors, onTextFieldChange }) => {
    const deliveryAddress = newOrder.orderId ? newOrder.deliveryAddress : practice?.fullAddress
    return (
        <>
            <Section title="Delivery" />
            <FormControl style={{ marginBottom: 15 }}>
                <Typography style={{ marginBottom: 10 }}>Select the delivery address for your order</Typography>
                <RadioGroup name="deliveryOption" value={newOrder.deliveryOption} onChange={onTextFieldChange}>
                    <FormControlLabel value={defaultOption} control={<Radio />} label={deliveryAddress} />
                    <FormControlLabel value={otherOption} control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>

            {newOrder.deliveryOption === otherOption &&
                deliveryAddressAttributes.map(attr =>
                    attr.items ? (
                        <DeliveryFormControl
                            key={attr.id}
                            newOrder={newOrder}
                            formErrors={formErrors}
                            attribute={attr}
                            onChange={onTextFieldChange}
                        />
                    ) : (
                        <DeliveryTextField
                            key={attr.id}
                            newOrder={newOrder}
                            formErrors={formErrors}
                            attribute={attr}
                            onChange={onTextFieldChange}
                        />
                    )
                )}
        </>
    )
}

const TermsAndConditions = ({ checkboxValue, checkboxClicked }) => (
    <FormControlLabel
        control={<Checkbox checked={checkboxValue} name="acceptTC" onChange={_ => checkboxClicked(!checkboxValue)} />}
        label={
            <div>
                <span>I have read and I accept the </span>
                <a href={config.termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
                    terms and conditions
                </a>
                <span> from Smile Academy </span>
            </div>
        }
        style={{ marginTop: 15, marginBottom: 35 }}
    />
)

// TODO get practice name and address
const InvoicingDelivery = ({ practice, newOrder, formErrors, onTextFieldChange, termsAccepted, termsClicked }) => (
    <>
        <InvoicingSection
            newOrder={newOrder}
            practice={practice}
            formErrors={formErrors}
            onTextFieldChange={onTextFieldChange}
        />
        <DeliverySection
            newOrder={newOrder}
            practice={practice}
            formErrors={formErrors}
            onTextFieldChange={onTextFieldChange}
        />
        <TermsAndConditions checkboxValue={termsAccepted} checkboxClicked={termsClicked} />
    </>
)

export default InvoicingDelivery
