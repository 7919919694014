import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
import { Typography } from '../../components/Wrappers/Wrappers';
import Widget from '../../components/Widget/Widget';
import { Breadcrumb, FormFooter, CaseDesign, CaseMethod, FileAttachment, OrderConfirmation } from './components';

// context
import { useOrderDispatch, useOrderState, actions as orderActions } from '../../context/OrderContext';
import { usePracticeDispatch, usePracticeState, actions as practiceActions } from '../../context/PracticeContext';

// form helper
import {
    emptyOrder,
    caseDesignAttributes,
    findMissingRequiredFields,
    refinementFormStepsTitle,
    hasMissingRequiredFields,
    scanMethod,
} from './helpers/form';

// Start refinement order from emptyOrder but init some properties from the initial order
const initRefinementOrder = (initialOrder) => {
    const refinementOrder = {
        ...emptyOrder,
        refinement: 'Yes',
        method: scanMethod,
        treatmentType: initialOrder.treatmentType,
        previousOrderId: initialOrder.orderId,
        previousCaseNumber: initialOrder.caseNumber,
        deliveryOption: initialOrder.deliveryOption,
        deliveryAddress: initialOrder.deliveryAddress,
        invoicingOption: initialOrder.invoicingOption,
        invoicingRecipient: initialOrder.invoicingRecipient,
        manufacturer: initialOrder.manufacturer,
        patientName: initialOrder.patientName,
        prescriberName: initialOrder.prescriberName,
        practiceId: initialOrder.practiceId,
        provideTreatmentPlan: initialOrder.provideTreatmentPlan,
    };

    const propsToRemove = ['deliveryStreetAddress', 'deliverySuburb', 'deliveryPostcode', 'deliveryState'];
    propsToRemove.forEach((attr) => delete refinementOrder[attr]);

    return refinementOrder;
};

const RefineOrder = () => {
    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();

    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();

    const history = useHistory();
    const location = useLocation();

    const [refinementOrder, setRefinementOrder] = useState(emptyOrder);
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState({});

    const allowFullArchTreatment = practiceState.currentPractice?.allowFullArchTreatment === 'Yes' ?? false;

    // ===== Hooks  =====

    // find the order when ids are passed as query strings
    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const practiceId = queryString.get('practiceId');
        const orderId = queryString.get('orderId');
        if (!practiceId || !orderId) {
            toast.error('Unable to find practice id or order id');
            setTimeout(() => backToList(), 2000);
        } else if (!orderState.currentOrder || orderState.currentOrder.orderId !== orderId) {
            orderActions.doFind(practiceId, orderId)(orderDispatch);
        }
        practiceActions.doFind(practiceId)(practiceDispatch);
    }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    // pre-load the refinement order with parent order
    useEffect(() => {
        if (orderState.currentOrder && refinementOrder?.orderId !== orderState.currentOrder.orderId) {
            const newRefinement = initRefinementOrder(orderState.currentOrder);
            setRefinementOrder(newRefinement);
        }
    }, [orderState.currentOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    // ===== Navigation  =====

    const backToList = () => history.goBack();

    const goNext = (step = 1) => setActiveStep((prevActiveStep) => prevActiveStep + step);

    const goBack = (step = 1) => setActiveStep((prevActiveStep) => prevActiveStep - step);

    const checkCaseDesign = () => {
        const caseDesignForm = caseDesignAttributes(refinementOrder, allowFullArchTreatment);
        const requiredFieldErrors = findMissingRequiredFields(caseDesignForm, refinementOrder);
        setError({ ...error, ...requiredFieldErrors });
        if (!hasMissingRequiredFields(requiredFieldErrors)) goNext();
    };

    const checkMethod = () => {
        if (!refinementOrder.method) {
            toast.error('Please select an option');
        } else {
            goNext();
        }
    };

    const checkFileAttachment = () => {
        if (!orderState.fileUpload) {
            toast.error("Please upload the patient's files");
        } else {
            doSubmit(refinementOrder, history);
            goNext();
        }
    };

    const checkOrderConfirmation = () => history.push('/app/dashboard');

    const formStepsValidation = [checkCaseDesign, checkMethod, checkFileAttachment, checkOrderConfirmation];

    // ===== Handlers  =====

    const handleTextFieldChange = (e) => {
        if (e.target.required) setError({ ...error, [e.target.name]: !e.target.value });
        setRefinementOrder({ ...refinementOrder, [e.target.name]: e.target.value });
    };

    const handleMethodClicked = (method) => (_) => setRefinementOrder({ ...refinementOrder, method });

    const handleNext = async () => formStepsValidation[activeStep]();

    const handleBack = () => goBack();

    const doSubmit = async (formValues) => {
        const data = { ...formValues, fileUpload: orderState.fileUpload };
        data.territory = practiceState.currentPractice.territory;
        if (data.orderId) {
            // finalize a refinement that was waiting for impression
            await orderActions.doFinalize(data.orderId, data)(orderDispatch);
        } else {
            // create a new refinement
            await orderActions.doCreate(data)(orderDispatch);
        }
    };

    const refinementFormStepsComponent = [
        <CaseDesign
            newOrder={refinementOrder}
            allowFullArchTreatment={allowFullArchTreatment}
            formErrors={error}
            onTextFieldChange={handleTextFieldChange}
        />,
        <CaseMethod newOrder={refinementOrder} methodClicked={handleMethodClicked} />,
        <FileAttachment newOrder={refinementOrder} />,
        <OrderConfirmation newOrder={refinementOrder} />,
    ];

    return (
        <Grid container spacing={3}>
            <Breadcrumb activeStep={activeStep} stepsTitle={refinementFormStepsTitle} />
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={600}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 30 }}>
                                {refinementFormStepsTitle[activeStep]}
                            </Typography>
                            {refinementFormStepsComponent[activeStep]}
                            <FormFooter
                                activeStep={activeStep}
                                isRefinement={true}
                                onClickNext={handleNext}
                                onClickBack={handleBack}
                            />
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default RefineOrder;
