import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    card: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        margin: theme.spacing(4),
        width: '30rem',
    },
    cardMediaHidden: {
        margin: theme.spacing(4),
        width: '30rem',
        visibility: 'hidden',
    },
    media: {
        height: '140px',
    },
}))
